<template>
  <div class="p-4 w-full">
    <a
      :href="`#${target}`"
      class="tracking-[5px] animate-bounce bg-white bg-opacity-20 font-light uppercase drop-shadow-lg block w-full py-2 px-8 text-white bottom-0 text-[1em] font-bold md:text-[3em] border-2"
      >Assinar grupo VIP</a
    >
  </div>
</template>

<script>
export default {
    props: {
        target: {default: 'assintaura'}
    }
};
</script>

<style></style>
