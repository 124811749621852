<template>
  <div class="home bg-preto min-h-[100vh] flex md:flex-wrap flex-col  md:flex-row justify-start">
    <section>
      <img class="bg-img sepia" src="@/assets/foto2.png" alt="">
      <header class="opacity-90 drop-shadow-lg p-4 fonte text-white text-[2em]">Everaldo e Richiane</header>
      <p class="text-white pr-4 mt-auto mb-20 leading-[45px] font-light text-left pl-4 z-20 text-[2em]">
        <span class="text-yellow-400 font-bold text-[1.3em] block">Entretenimento<br/>adulto</span> para casais<br/>e solteiros
      </p>
      <botao-assinatura target="checkout" class="md:hidden"></botao-assinatura>
    </section>
    <section id="saibamais">
      <img class="bg-img sepia z-0 " src="@/assets/assinatura.png" alt="">
      <div class="z-10">

        <header class="opacity-90 drop-shadow-lg p-4 fonte text-white text-[2em] text-left">O que fazemos?</header>
        <p class="text-white pr-[50vw] md:pr-[100px] text-left p-4">Estamos no meio do entretenimento adulto a muitos anos.</p>
        <p class="text-white pr-[50vw] md:pr-[100px] text-left p-4">SOMOS DIGITAL!</p>
        <p class="text-white pr-[50vw] md:pr-[100px] text-left p-4">Trabalhamos com conteúdo fotos e vídeos.</p>
        <p class="text-white pr-[50vw] md:pr-[100px] text-left text-[2em] p-4 font-bold">Grupo no Telegram</p>
        <div class="w-full grid grid-cols-2 md:grid-cols-2 p-2 gap-2">
          <img :class="{active: index == 1}" class="imggal w-full saturate-0 hover:saturate-100 h-[200px] md:h-[200px] object-cover object-center" src="@/assets/galeria/1.jpg" alt="">
          <img :class="{active: index == 2}" class="imggal w-full saturate-0 hover:saturate-100 h-[200px] md:h-[200px] object-cover object-center" src="@/assets/galeria/5.jpg" alt="">
          <img :class="{active: index == 3}" class="imggal w-full saturate-0 hover:saturate-100 h-[200px] md:h-[200px] object-cover object-center" src="@/assets/galeria/3.jpg" alt="">
          <img :class="{active: index == 4}" class="imggal w-full saturate-0 hover:saturate-100 h-[200px] md:h-[200px] object-cover object-center" src="@/assets/galeria/4.jpg" alt="">
        </div>
        <botao-assinatura target="checkout" class="mt-auto md:hidden"></botao-assinatura>
      </div>
    </section>
    <section id="checkout">
      <img class="bg-img sepia z-0 " src="@/assets/checkout2.png" alt="">
      <div class="z-10 w-full p-4">
        <header class="opacity-90 drop-shadow-lg fonte text-white text-[2em] text-center">Assine o grupo!</header>
        <p class="text-white mb-10 md:mb-5">Para assinar o grupo pague o QR Code abaixo com PIX e envie o comprovante no whatsapp</p>
        <strong class="text-white mb-10 md:mb-5 block">Apenas R$30,00 mensal</strong>
        <qr-code></qr-code>
        <a href="https://wa.me/5551981108038" class="bg-green-500 mt-4 uppercase text-[1em] font-bold tracking-[4px] text-white w-full block py-4">Enviar comprovante</a>
      </div>
    </section>
    <!-- <footer class="text-white w-full py-2">
      Desenvolvido por <strong>ER Soluções Web LTDA</strong>
    </footer> -->
  </div>
</template>

<script>
import BotaoAssinatura from '../components/BotaoAssinatura.vue'
import QrCode from '../components/QrCode.vue'
// @ is an alias to /src

export default {
  name: 'HomeView',
  components: {
    BotaoAssinatura,
    QrCode
  },
  data() {
    return {
      index: 0
    }
  },
  mounted() {
    setInterval(() => {
      this.index = Math.ceil(this.index % 4)
      this.index++;
    }, 3000)
  }
}
</script>

<style scoped>

section {
  @apply md:w-1/3 relative flex flex-col justify-start min-h-[calc(100dvh)] items-start;
}
.bg-img {
  @apply absolute top-0 left-0 w-full mt-auto h-full object-cover object-center opacity-50 ml-auto;
}
</style>
