<template>
  <div class="flex flex-col justify-center w-full">
    <img
      class="md:max-w-[250px] m-auto w-full md:mb-4"
      :src="`data:image/png;base64,${qrcode}`"
      alt=""
      v-if="qrcode"
    />
    <div
      @click="copy(pix, $event)"
      class="text-[1em] mb-8 md:mb-4 py-3 tracking-[5px] bg-yellow-400 text-black uppercase font-bold"
    >
      copiar qr code
    </div>
    <div class="text-[2em] text-white flex flex-col gap-4">
      <div>Seu código é:</div>
      <span class="text-[0.5em] bg-white text-black p-2">{{ codigo }}</span>
    </div>
    <div
      @click="copy(codigo, $event)"
      class="text-[1em] py-3 tracking-[5px] bg-black text-white uppercase font-light"
    >
      copiar código
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import { copyText } from "vue3-clipboard";

export default {
  data() {
    return {
      qrcode: null,
      txid: null,
      pix: null,
      codigo: sessionStorage.codigo,
    };
  },
  mounted() {
    if (!this.codigo) {
      this.codigo = uuidv4();
      sessionStorage.codigo = this.codigo;
    }
    fetch(
      `https://pix-pagseguro-sem-taxas.ersolucoesweb.com.br/wp-json/pix/v1/create2/3000?infoAdicionais[0][nome]=CODIGO&infoAdicionais[0][valor]=${this.codigo}`
    )
      .then((res) => res.json())
      .then((res) => {
        this.txid = res[0];
        this.pix = res[1];
        this.qrcode = res[2];
        this.checkStatus();
      });
  },
  methods: {
    copy(text, e) {
      let msg = e.target.innerHTML;
      e.target.innerHTML = "Texto copiado!";
      setTimeout(() => {
        e.target.innerHTML = msg;
      }, 3000);
      copyText(text, undefined, () => {});
    },
    checkStatus() {
      fetch(
        `https://pix-pagseguro-sem-taxas.ersolucoesweb.com.br/wp-json/pix/v1/status/${this.txid}`
      )
        .then((res) => res.text())
        .then((status) => {
          if (parseInt(status) === 1) {
            return (this.pago = true);
          }
          setTimeout(this.checkStatus, 5000);
        });
    },
  },
};
</script>

<style></style>
